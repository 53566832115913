/* Base styling for the entire app */
#root {
  --font-size: 1em;
  --dark-bg: #222222;
  /* Primary dark background */
  --dark-secondary-bg: rgba(0, 0, 50, 1);
  /* Secondary background for cards and areas needing slight contrast */
  --accent-color: #ff9900;
  /* Bright orange for accents */
  --text-color: #ffffff;
  /* White text for general use */

  --bs-table-bg: white;
  /* Black
  
  margin: 0 auto;
  margin-top: 3em;
  padding: 0;
  text-align: center;

  color: var(--text-color);
  background-color: var(--dark-bg);
  font-family: "roboto", sans-serif; /* Assuming Arial, adjust as per the design specifics */
  font-size: var(--font-size);
}

.navbarMenu {
  background-color: var(--dark-bg);
  /* Background image and gradient overlay */
  background: linear-gradient(rgba(165, 165, 165, 0.95), rgba(0, 0, 0, 0.85));
}

body {
  background-color: var(--dark-bg);
  /* Background image and gradient overlay */
  background: linear-gradient(rgba(0, 0, 0, .95), rgba(26, 26, 26, 0.85)),
    url("/src/assets/images/trust_love_guitar_bw.png");

  /* Background properties */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  /* Other styles */
  margin: 0;
  padding: 0;
  width: 100%;
}

.text-accent {

  color: var(--accent-color);
}

/* Card Customization */
.card {
  border: none;
}

.card .card-body {
  color: var(--text-color);
}

/* Navbar, if present */
.navbar {
  background-color: var(--dark-secondary-bg);
}

/* Links and other elements */
a,
.btn-link {
  color: var(--accent-color);
}

a:hover,
.btn-link:hover {
  color: darken(var(--accent-color), 10%);
}

/* Specific overrides or additional customizations */
.custom-class {
  background-color: var(--dark-secondary-bg);
  color: var(--text-color);
}

/* This is a basic setup, and you might need to expand upon or adjust these styles 
   depending on the exact components and layout specifics of your application. */

/* Styling for logos */
.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
}

.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}

.logo.react:hover {
  filter: drop-shadow(0 0 2em #61dafbaa);
}

/* Animation for the logo */
@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
}

/* Styling for documentation links */
.read-the-docs {
  color: #bbb;
  /* Light grey for readable contrast */
}

/* Hero section styling for dark mode */
.hero {
  background: url("assets/images/tl_necklaces.jpeg") no-repeat center center/cover;
  opacity: .95;
  min-height: 60vh;
}

.App button {
  background-color: #fa8231;
  border: none;
  padding: 10px 20px;
  color: white;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #e67e22;
}

/* Styling for feature sections in dark mode */
.features div {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0px;
}

/* Testimonials section styling for dark mode */

/* Call to action section styling */
.cta-final {
  background-color: #fa8231;
  color: #fff;
  padding: 40px 20px;
}

.cta-final button {
  background-color: #fff;
  color: #fa8231;
  padding: 10px 20px;
  font-size: 18px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-final button:hover {
  background-color: #e67e22;
  color: #fff;
}

/* Footer styling for dark mode */
footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
}

footer a {
  color: #fa8231;
  margin: 0 10px;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

/* Responsive styling for smaller devices */
@media (max-width: 768px) {
  .features div {
    width: 100%;
  }
}

.hero-section {
  height: 100vh;
  background: url("./assets/images/Banner-hero.WEBP") no-repeat center center/cover;
  text-align: center;
}

h1,
h4 {
  color: var(--text-color);
}

h2 {
  color: #ff9900;
  /* Accent color for headers */
}

.social-links a {
  color: #ffffff;
  /* Orange color for icons */
  font-size: 48px;
  /* Larger icons for better visibility */
  transition: color 0.3s;
}

.social-links a:hover {
  color: #000000;
  /* Lighter color on hover */
}


.form-control {
  background-color: #333;
  /* Dark input fields for contrast */
  color: #fff;
  /* White text for readability */
  border: none;
}

.form-control:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 106, 0, 0.5);
  /* Glow effect on focus */
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ddd;
  /* Lighter placeholder text for better readability */
  opacity: 1;
  /* Firefox */
}

.background-container {
  position: relative;
  min-height: 300vh;
  width: 100%;
  overflow: hidden;
  /* Hide any overflow from the background */
}

.background-container>.RayCastWaves {
  position: absolute;
  min-height: 320em;
  /* Make the container fill the viewport height */
  left: 0;
  width: 100%;
  z-index: -10;
  /* Ensure it is behind other content */
  pointer-events: none;
  /* Prevent the background from capturing pointer events */
}

.content-container {
  position: relative;

  margin: 3em;
  top: 0px;

  z-index: 1;
  /* Ensure it is on top of the background */
  pointer-events: auto;
  /* Ensure this container is interactive */
}

.carouselCard {
  height: 550px;
  min-width: 550px;
}


.thumbnailImage {
  margin-right: 1em;
  float: left;
  object-fit: cover;
  border-radius: 10px;
  width: 100%;
  height: 14em;
}

.coolImage {
  margin-right: 1em;
  float: left;
  object-fit: cover;
  border-radius: 10px;
  width: 100%;
  min-height: 250px;
  max-height: 250px;

}

.menuTextContent {

  margin-left: 5px;
  margin-right: 5px;
  color: var(--accent-color);
  text-shadow: rgba(0, 0, 0, 0.4) -2px -2px 1px;
  white-space: nowrap;
  font-weight: 700;
  font-size: 1.2em;
}

.menuTextContentSM {

  margin-left: 5px;
  margin-right: 5px;
  color: var(--text-color);
  text-shadow: rgba(0, 0, 0, 0.4) -2px -2px 1px;
  white-space: nowrap;
  font-weight: 500;
  font-size: 1em;
}

.menuText {

  color: var(--text-color);
  text-shadow: rgba(0, 0, 0, 0.4) -2px -2px 1px;
  white-space: nowrap;
  font-weight: 500;
  font-size: 1em;
  background-color: rgba(0, 0, 0, .25);
  border-radius: 10px;
  min-width: 80%;
  padding: .5em;
}

.coolText {
  background-color: rgba(0, 0, 0, .05);
  overflow-Y: scroll;
  border-radius: 10px;
  min-width: 100%;
  padding: 1em;
  min-height: 260px;
  max-height: 260px;
}


/* Responsive styling for smaller devices */
@media (max-width: 6640px) {


  .menuTextContent {
    display: none;
  }

  .menuTextContentSM {
    display: none;
  }

  .content-container {
    margin-top: -310em;
  }

}

/* Responsive styling for smaller devices */
@media (max-width: 1240px) {

  .content-container {
    margin-top: -310em;
  }

}


/* Responsive styling for smaller devices */
@media (max-width: 933px) {

  .content-container {
    margin-top: -310em;
  }

  .features div {
    width: 100%;
  }

}

/* Responsive styling for smaller devices */
@media (max-width: 768px) {
  .menuTextContent {
    display: inline;
  }

  .menuTextContentSM {
    display: inline;
  }

  .content-container {
    margin-top: -310em;
  }

  .features div {
    width: 100%;
  }

  .coolImage {
    width: 100%;
    min-height: 240px;
    max-height: 240px;
  }

  .coolText {
    overflow-Y: scroll;
    min-width: 100%;
    padding: 0em;
    margin: 0em;
    min-height: 250px;
    max-height: 250px;
  }

  .carousel {
    padding: 0em;
  }

  .carouselCard {
    min-width: 250px;
  }

  .App {
    padding: 0em;
    margin: 0em;
  }
}


.titleImage {
  max-width: 100%;
}


.col-md-6 {
  background-color: "red";
  border: 0;
}

.megaIcon {
  opacity: 1;
  color: var(--text-color);
  z-index: 1;
  word-wrap: break-word;
  margin-top: -.5em;
  margin-left: -1em;
  margin-right: .5em;

  float: left;

}



/* Active effect */